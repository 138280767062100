.bricks-cards {
  display: grid;
  grid-gap: 17px;
  grid-template-columns: repeat(auto-fill, minmax(287px, 1fr));
}

.bricks-cards__card {
  background-color: #fff;
  border: 1px solid #e5f0f5;
  color: #4f6d85;
  border-radius: 3px;
}
