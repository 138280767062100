.tuby-upload-image__wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.tuby-upload-image__label {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.336);
  color: white;
  font-size: 18pt;
  cursor: pointer;
}

.tuby-upload-image__wrapper:hover .tuby-upload-image__label {
  opacity: 1;
}

.tuby-upload-image__input {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
}

.tuby-image__label {
  display: block;
  margin: 0 0 0.28571429rem 0;
  font-size: 0.92857143em;
  font-weight: 700;
  text-transform: none;
}
.tuby-image__label--error {
  color: #d15353;
}

.tuby-input__label {
  margin-bottom: 5px;
}
.tuby-input--error {
  color: #d15353;
  font-size: 12px;
  padding-left: 5px;
}

input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  font-size: 100%;
  line-height: 1.5;
  background-color: white;
  margin: 0;
  width: 365px;
}

.ui.medium.image,
.ui.medium.images .image,
.ui.medium.images img,
.ui.medium.images svg {
  width: 365px;
  height: 300px;
  font-size: 1rem;
}
