.tuby-input__label {
  margin-bottom: 5px;
}
.tuby-input--error {
  color: #d15353;
  font-size: 12px;
  padding-left: 5px;
}

.tuby-popover {
  position: 'absolute';
  z-index: '2';
}

.tuby-cover {
  position: 'fixed';
  top: '0px';
  right: '0px';
  bottom: '0px';
  left: '0px';
}

.tuby-buttom {
  margin: 100px;
  padding: 100px;
}

.tuby-color-picker {
  margin-bottom: 5px;
}
