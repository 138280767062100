html {
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: #f1f1f1;
}
.wrapper {
  /* display: flex; */
}
.header-wrapper {
  margin-bottom: 50px;
}
.content-wrapper {
  display: flex;
  width: 100%;
  height: 100vh;
}
.pages-wrapper {
  width: 100%;
  height: 100vh;
  position: relative;
}
.sidebar-wrapper {
  width: 240px;
  height: 100vh;
  position: relative;
  z-index: 10;
}
.sidebar-cover {
  width: inherit;
}

@media only screen and (max-width: 768px) {
  .sidebar-wrapper {
    visibility: hidden;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 10000;
    position: fixed;
  }
  .tuby-sidebar {
    width: 70%;
    left: -100%;
  }
  .sidebar-wrapper--active-mobile {
    visibility: visible;
  }
  .sidebar-wrapper--active-mobile .tuby-sidebar {
    left: 0;
  }
}
