.theme-card {
  position: relative;
  cursor: pointer;
}
.theme-card__image {
  height: 170px;
  position: relative;
}
.theme-card__img {
  height: 100%;
  width: 100%;
}

.theme-card__details {
  padding: 10px;
}
.theme-card__title {
  height: 36px;
  overflow: hidden;
}
.theme-card__actions {
  position: absolute;
  z-index: 10;
  right: 0;
  bottom: 0;
  padding: 10px;
}
