.ui.slider.checkbox input:checked ~ .box:before,
.ui.slider.checkbox input:checked ~ label:before {
  background-color: #ffffff !important;
}

.ui.slider.checkbox .box:before,
.ui.slider.checkbox label:before {
  background-color: white;
  width: 3.5rem;
}

.ui.slider.checkbox input:checked ~ .box::after,
.ui.slider.checkbox input:checked ~ label::after {
  background-color: rgb(84, 41, 95) !important;
}
