.video-card {
  position: relative;
}
.video-card:hover {
  cursor: pointer;
}
.video-card__image {
  height: 150px;
  position: relative;
}
.video-card__img {
  height: 100%;
  width: 100%;
}
.video-card__details {
  padding: 10px;
}

.video-card__playbtn {
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
  color: #fff;
  background: rgba(0, 0, 0, 0.3);
}
.video-card__title {
  height: 36px;
  overflow: hidden;
}
.video-card__details--item {
  padding: 3px 0;
}
.video-card__actions {
  position: absolute;
  z-index: 10;
  right: 0;
  bottom: 0;
  padding: 10px;
}
.video-card__details--channel-name {
  height: 40px;
  overflow: hidden;
}
