.channel-info {
  display: flex;
}
.channel-info__detils {
  display: inline-block;
  margin-left: 10px;
}
.channel-info__row {
  padding: 2px 0;
  font-size: 15px;
}
.channel-info_thumnail {
  min-width: 160px;
}
@media only screen and (max-width: 768px) {
  .channel-info {
    display: block;
  }
  .channel-info_thumnail {
    min-width: 100%;
    margin-bottom: 30px;
  }
  .channel-info_thumnail img {
    width: 100% !important;
  }
}
