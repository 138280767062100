@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700');

.bricks-pagination {
  background: #fff;
  display: inline-block;
  padding: 5px 10px;
  border-radius: 3px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}
.bricks-pagination button {
  border: 0;
  border-radius: 100%;
  width: 32px;
  height: 32px;
  outline: inherit;
  cursor: pointer;
}
.bricks-pagination button:hover {
  background: #f5f7f9;
}
.bricks-pagination button.bg {
  background: #f5f7f9;
}
.bricks-pagination button.bg:hover {
  background: #e9ebec;
}
.bricks-pagination button.dotted:hover {
  cursor: default;
  background: none;
}
.bricks-pagination button.active,
.bricks-pagination button.active > span {
  background: #d25081;
  color: #fff;
}
.bricks-pagination button:first-child {
  margin-left: 0;
}
.bricks-pagination button {
  margin-left: 8px;
  position: relative;
}
.bricks-pagination > button > span {
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  float: left;
  float: left;
  text-align: center;
  display: inline-block;
  color: #a6bdcf;
  width: 100%;
  margin-top: 2px;
}

.bricks-pagination .arrow {
  border: 1px solid #4f6d85;
  opacity: 0.35;
  width: 7px;
  height: 7px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 10px;
}
.bricks-pagination .arrow.left {
  border-top: 0;
  border-right: 0;
  margin-left: 7px;
  left: 7px;
}
.bricks-pagination .arrow.right {
  border-bottom: 0;
  border-left: 0;
  margin-right: 7px;
  right: 7px;
}
.bricks-pagination > button > span.double > .arrow {
  margin-top: 3px;
}
.bricks-pagination > button > span.double.left > .arrow {
  left: 5px;
}
.bricks-pagination > button > span.double.left > .arrow:nth-child(2) {
  left: 9px;
}
.bricks-pagination > button > span.double.right > .arrow {
  right: 5px;
}
.bricks-pagination > button > span.double.right > .arrow:nth-child(2) {
  right: 9px;
}
.bricks-pagination button:disabled {
  opacity: 0.7;
}
.bricks-pagination button:disabled:hover {
  cursor: auto;
  background: #f5f7f9;
}
