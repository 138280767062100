.ui.primary.button,
.ui.primary.buttons .button {
  background-color: #c73f76;
}
.ui.primary.button:hover,
.ui.primary.buttons .button:hover {
  background-color: #a93463;
}
.ui.primary.button:active,
.ui.primary.buttons .button:active {
  background-color: #642b74;
}
.ui.primary.button:focus,
.ui.primary.buttons .button:focus {
  background-color: #703081;
}
