.video-form {
  padding: 10px 50px !important;
  position: relative;
}

.video-form_field {
  margin-bottom: 25px !important;
}

.video-form_button {
  margin-top: 15px !important;
  text-align: center !important;
}
.video-form--error-message {
  width: 100% !important;
}
