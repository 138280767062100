@font-face {
  font-family: 'Conv_STC-Regular';
  src: url('/fonts/STC-Regular.eot');
  src: local('?'), url('/fonts/STC-Regular.woff') format('woff'),
    url('/fonts/STC-Regular.ttf') format('truetype'),
    url('/fonts/STC-Regular.svg') format('svg');

  font-weight: normal;
  font-style: normal;
}
.swal-button--danger,
.swal-button--cancel,
.swal-button--confirm,
.swal-button--confirm:focus,
.swal-button--cancel:focus,
.swal-button--catch,
.swal-button--catch:focus {
  font-family: 'Roboto', sans-serif;
  border-radius: 3px;
  font-weight: 600;
  outline: none;
  box-shadow: 0 0 0;
}

.swal-button--danger:hover,
.swal-button--confirm:hover,
.swal-button--catch:hover {
  background-color: #d04067;
}
.swal-button:not([disabled]):hover {
  background-color: #d04067;
}
.swal-button--cancel:hover,
.swal-button--cancel:not([disabled]):hover {
  background-color: #e0dede;
}
.swal-button--danger,
.swal-button--catch,
.swal-button--confirm {
  background-color: #ea4773;
}
.swal-footer {
  text-align: center;
}
