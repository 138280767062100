html[dir='rtl'] .tuby-input__label {
  text-align: right;
}

html[dir='rtl'] .tuby-input--error {
  text-align: right;
}

html[dir='rtl'] .ui.fluid.input > input {
  text-align: right;
}
