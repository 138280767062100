.tuby-menu__item {
  padding: 12px 10px 12px 10px;
  cursor: pointer;
  color: #ffffff;
  display: inline-block;
  width: 100%;
}
.tuby-menu__item:hover {
  background-color: #f1eaf2;
  color: #703081;
}

.tuby-menu__item--active {
  color: #703081;
  background-color: #f1eaf2;
  box-shadow: inset 0.25rem 0 0 #703081;
}
