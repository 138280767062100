.theme-info {
  display: flex;
}
.theme-info__detils {
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
}
.theme-info__row {
  padding: 2px 0;
  font-size: 15px;
}
.theme-info_thumnail {
  min-width: 160px;
}
.theme-info_thumnail--image {
  margin-left: 10px;
  margin-right: 10px;
}
@media only screen and (max-width: 768px) {
  .theme-info {
    display: block;
  }
  .theme-info_thumnail {
    min-width: 100%;
    margin-bottom: 30px;
  }
  .theme-info_thumnail img {
    width: 100% !important;
  }
}

.ui.card > .image,
.ui.cards > .card > .image {
  position: relative;
  display: block;
  -webkit-flex: 0 0 auto;
  flex: 0 0 auto;
  padding: 0;
  background: rgba(0, 0, 0, 0.05);
  height: 200px;
  width: 300px;
}

.theme-container {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 20px;
  word-break: break-all;
}

.theme-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-left: 20px;
}

.theme-column {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: wrap;
  min-width: 25%;
}

.theme-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 10px;
}
