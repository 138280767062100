.admin-form {
  margin: 10px 50px !important;
}

.admin-form_field {
  margin-bottom: 20px !important;
}

.admin-form_button {
  margin-top: 15px !important;
  text-align: center !important;
}
.admin-form_error-message {
  width: 100% !important;
}
