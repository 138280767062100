.tu-header {
  height: 50px;
  background: white;
  width: 100%;
  border-bottom: 1px solid #ededed;
  position: fixed;
  z-index: 100;
  top: 0;
  padding: 5px 30px;
}

.tu-header__left-side {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
  width: 150px;
}

.tu-header__sidebar-togglor {
  color: #cd4075;
  cursor: pointer;
}
