.tuby-editor__label {
  margin-bottom: 5px;
}
.tuby-editor--error {
  color: #d15353;
  font-size: 12px;
  padding-left: 5px;
}
.RichTextEditor__editor___1QqIU .public-DraftEditor-content {
  min-height: 150px;
}
