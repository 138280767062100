.tuby-sidebar {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  background: linear-gradient(-135deg, #cd4075 0%, #6c2e79 100%);
  width: inherit;
  position: fixed;
  padding-top: 20px;
  transition: 0.3s;
}

.tuby-sidebar_logout {
  display: flex;
  height: 200px;
  justify-content: center;
  align-items: center;
}

.tuby-item__logout--icon {
  color: #ffffff;
  cursor: pointer;
}
