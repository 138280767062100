.rtl {
  text-align: right !important;
  direction: rtl;
}
.admin-details-table {
  width: 100%;
  padding: 10px 40px;
}

.admin-details-table_th {
  width: 100px;
  height: 50px;
}
