@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700);
.bricks-sem-input {
  margin-bottom: 25px;
}
.bricks-sem-input__error-msg {
  position: absolute;
  color: red;
  font-size: 11px;
}

.Change.ui.input > input:focus {
  border: 1px solid purple !important;
}

.tu-form-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  border: 1px solid #ededed;
}

.tu-header {
  height: 50px;
  background: white;
  width: 100%;
  border-bottom: 1px solid #ededed;
  position: fixed;
  z-index: 100;
  top: 0;
  padding: 5px 30px;
}

.tu-header__left-side {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
  width: 150px;
}

.tu-header__sidebar-togglor {
  color: #cd4075;
  cursor: pointer;
}

.tu-form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f3f3f3;
}

.tuby-item__icon {
  margin: 0px 15px 0px 15px !important;
  font-size: 12pt !important;
}

html[dir='rtl'] .tuby-menu__item--active {
  box-shadow: inset -0.25rem 0 0 #703081;
}

.tuby-menu__item {
  padding: 12px 10px 12px 10px;
  cursor: pointer;
  color: #ffffff;
  display: inline-block;
  width: 100%;
}
.tuby-menu__item:hover {
  background-color: #f1eaf2;
  color: #703081;
}

.tuby-menu__item--active {
  color: #703081;
  background-color: #f1eaf2;
  box-shadow: inset 0.25rem 0 0 #703081;
}

.tuby-sidebar {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  background: -webkit-linear-gradient(225deg, #cd4075 0%, #6c2e79 100%);
  background: linear-gradient(-135deg, #cd4075 0%, #6c2e79 100%);
  width: inherit;
  position: fixed;
  padding-top: 20px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.tuby-sidebar_logout {
  display: flex;
  height: 200px;
  justify-content: center;
  align-items: center;
}

.tuby-item__logout--icon {
  color: #ffffff;
  cursor: pointer;
}

html {
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: #f1f1f1;
}
.wrapper {
  /* display: flex; */
}
.header-wrapper {
  margin-bottom: 50px;
}
.content-wrapper {
  display: flex;
  width: 100%;
  height: 100vh;
}
.pages-wrapper {
  width: 100%;
  height: 100vh;
  position: relative;
}
.sidebar-wrapper {
  width: 240px;
  height: 100vh;
  position: relative;
  z-index: 10;
}
.sidebar-cover {
  width: inherit;
}

@media only screen and (max-width: 768px) {
  .sidebar-wrapper {
    visibility: hidden;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 10000;
    position: fixed;
  }
  .tuby-sidebar {
    width: 70%;
    left: -100%;
  }
  .sidebar-wrapper--active-mobile {
    visibility: visible;
  }
  .sidebar-wrapper--active-mobile .tuby-sidebar {
    left: 0;
  }
}

.rtl {
  direction: rtl;
}

.app-container {
  padding: 30px;
}
.container-header--left {
  text-align: left;
}
.container-header--right {
  text-align: right;
}
.container-body {
  padding-top: 50px;
}

html[dir='rtl'] .container-header--left {
  text-align: right;
}
html[dir='rtl'] .container-header--right {
  text-align: left;
}

.bricks-pagination {
  background: #fff;
  display: inline-block;
  padding: 5px 10px;
  border-radius: 3px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}
.bricks-pagination button {
  border: 0;
  border-radius: 100%;
  width: 32px;
  height: 32px;
  outline: inherit;
  cursor: pointer;
}
.bricks-pagination button:hover {
  background: #f5f7f9;
}
.bricks-pagination button.bg {
  background: #f5f7f9;
}
.bricks-pagination button.bg:hover {
  background: #e9ebec;
}
.bricks-pagination button.dotted:hover {
  cursor: default;
  background: none;
}
.bricks-pagination button.active,
.bricks-pagination button.active > span {
  background: #d25081;
  color: #fff;
}
.bricks-pagination button:first-child {
  margin-left: 0;
}
.bricks-pagination button {
  margin-left: 8px;
  position: relative;
}
.bricks-pagination > button > span {
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  float: left;
  float: left;
  text-align: center;
  display: inline-block;
  color: #a6bdcf;
  width: 100%;
  margin-top: 2px;
}

.bricks-pagination .arrow {
  border: 1px solid #4f6d85;
  opacity: 0.35;
  width: 7px;
  height: 7px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 10px;
}
.bricks-pagination .arrow.left {
  border-top: 0;
  border-right: 0;
  margin-left: 7px;
  left: 7px;
}
.bricks-pagination .arrow.right {
  border-bottom: 0;
  border-left: 0;
  margin-right: 7px;
  right: 7px;
}
.bricks-pagination > button > span.double > .arrow {
  margin-top: 3px;
}
.bricks-pagination > button > span.double.left > .arrow {
  left: 5px;
}
.bricks-pagination > button > span.double.left > .arrow:nth-child(2) {
  left: 9px;
}
.bricks-pagination > button > span.double.right > .arrow {
  right: 5px;
}
.bricks-pagination > button > span.double.right > .arrow:nth-child(2) {
  right: 9px;
}
.bricks-pagination button:disabled {
  opacity: 0.7;
}
.bricks-pagination button:disabled:hover {
  cursor: auto;
  background: #f5f7f9;
}

.bricks-cards {
  display: grid;
  grid-gap: 17px;
  grid-template-columns: repeat(auto-fill, minmax(287px, 1fr));
}

.bricks-cards__card {
  background-color: #fff;
  border: 1px solid #e5f0f5;
  color: #4f6d85;
  border-radius: 3px;
}

.channel-card {
  position: relative;
  cursor: pointer;
}
.channel-card__image {
  height: 170px;
  position: relative;
}
.channel-card__img {
  height: 100%;
  width: 100%;
}

.channel-card__details {
  padding: 10px;
}
.channel-card__title {
  height: 36px;
  overflow: hidden;
}
.channel-card__actions {
  position: absolute;
  z-index: 10;
  right: 0;
  bottom: 0;
  padding: 10px;
}

.tuby-input__label {
  margin-bottom: 5px;
}
.tuby-input--error {
  color: #d15353;
  font-size: 12px;
  padding-left: 5px;
}

html[dir='rtl'] .tuby-input__label {
  text-align: right;
}

html[dir='rtl'] .tuby-input--error {
  text-align: right;
}

html[dir='rtl'] .ui.fluid.input > input {
  text-align: right;
}

.tuby-input__label {
  margin-bottom: 5px;
}
.tuby-input--error {
  color: #d15353;
  font-size: 12px;
  padding-left: 5px;
}

html[dir='rtl'] .tuby-input__label {
  text-align: right;
}

html[dir='rtl'] .tuby-input--error {
  text-align: right;
}

html[dir='rtl'] .ui.fluid.input > input {
  text-align: right;
}

.channel-form {
  padding: 10px 50px !important;
  position: relative;
}

.channel-form_field {
  margin-bottom: 25px !important;
}

.channel-form_button {
  margin-top: 15px !important;
  text-align: center !important;
}
.channel-form--error-message {
  width: 100% !important;
}

.channel-info {
  display: flex;
}
.channel-info__detils {
  display: inline-block;
  margin-left: 10px;
}
.channel-info__row {
  padding: 2px 0;
  font-size: 15px;
}
.channel-info_thumnail {
  min-width: 160px;
}
@media only screen and (max-width: 768px) {
  .channel-info {
    display: block;
  }
  .channel-info_thumnail {
    min-width: 100%;
    margin-bottom: 30px;
  }
  .channel-info_thumnail img {
    width: 100% !important;
  }
}

.video-card {
  position: relative;
}
.video-card:hover {
  cursor: pointer;
}
.video-card__image {
  height: 150px;
  position: relative;
}
.video-card__img {
  height: 100%;
  width: 100%;
}
.video-card__details {
  padding: 10px;
}

.video-card__playbtn {
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
  color: #fff;
  background: rgba(0, 0, 0, 0.3);
}
.video-card__title {
  height: 36px;
  overflow: hidden;
}
.video-card__details--item {
  padding: 3px 0;
}
.video-card__actions {
  position: absolute;
  z-index: 10;
  right: 0;
  bottom: 0;
  padding: 10px;
}
.video-card__details--channel-name {
  height: 40px;
  overflow: hidden;
}

.page-container {
  padding: 50px;
}

.position-relative {
  position: relative;
}

html[dir='rtl'] .ui.table {
  text-align: right;
}

.admin-form {
  margin: 10px 50px !important;
}

.admin-form_field {
  margin-bottom: 25px !important;
}

.admin-form_button {
  margin-top: 15px !important;
  text-align: center !important;
}
.admin-form_error-message {
  width: 100% !important;
}

.admin-details-table {
  width: 100%;
  padding: 10px 40px;
}

.admin-details-table_th {
  width: 100px;
  height: 50px;
}

.tuby-input__label {
  margin-bottom: 5px;
}
.tuby-input--error {
  color: #d15353;
  font-size: 12px;
  padding-left: 5px;
}

.video-form {
  padding: 10px 50px !important;
  position: relative;
}

.video-form_field {
  margin-bottom: 25px !important;
}

.video-form_button {
  margin-top: 15px !important;
  text-align: center !important;
}
.video-form--error-message {
  width: 100% !important;
}

.react-datepicker-component
  .react-datepicker-input
  .button-wrapper
  .input-button {
  margin: 0 10px;
  background: transparent;
  font-size: 15px;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
  color: #f90084;
}

.tuby-input__label {
  margin-bottom: 5px;
}
.tuby-input--error {
  color: #d15353;
  font-size: 12px;
  padding-left: 5px;
}

html[dir='rtl'] .tuby-input__label {
  text-align: right;
}

html[dir='rtl'] .tuby-input--error {
  text-align: right;
}

html[dir='rtl'] .ui.fluid.input > input {
  text-align: right;
}

.admin-form {
  margin: 10px 50px !important;
}

.admin-form_field {
  margin-bottom: 20px !important;
}

.admin-form_button {
  margin-top: 15px !important;
  text-align: center !important;
}
.admin-form_error-message {
  width: 100% !important;
}

.tuby-editor__label {
  margin-bottom: 5px;
}
.tuby-editor--error {
  color: #d15353;
  font-size: 12px;
  padding-left: 5px;
}
.RichTextEditor__editor___1QqIU .public-DraftEditor-content {
  min-height: 150px;
}

.page-form {
  padding: 10px 50px !important;
  position: relative;
}

.page-form_field {
  margin-bottom: 25px !important;
}

.page-form_button {
  margin-top: 15px !important;
  text-align: center !important;
}
.page-form--error-message {
  width: 100% !important;
}

.rtl {
  text-align: right !important;
  direction: rtl;
}
.admin-details-table {
  width: 100%;
  padding: 10px 40px;
}

.admin-details-table_th {
  width: 100px;
  height: 50px;
}

.admin-form {
  margin: 10px 50px !important;
}

.admin-form_field {
  margin-bottom: 25px !important;
}

.admin-form_button {
  margin-top: 15px !important;
  text-align: center !important;
}
.admin-form_error-message {
  width: 100% !important;
}

.admin-details-table {
  width: 100%;
  padding: 10px 40px;
}

.admin-details-table_th {
  width: 100px;
  height: 50px;
}

.ui.slider.checkbox input:checked ~ .box:before,
.ui.slider.checkbox input:checked ~ label:before {
  background-color: #ffffff !important;
}

.ui.slider.checkbox .box:before,
.ui.slider.checkbox label:before {
  background-color: white;
  width: 3.5rem;
}

.ui.slider.checkbox input:checked ~ .box::after,
.ui.slider.checkbox input:checked ~ label::after {
  background-color: rgb(84, 41, 95) !important;
}

.theme-card {
  position: relative;
  cursor: pointer;
}
.theme-card__image {
  height: 170px;
  position: relative;
}
.theme-card__img {
  height: 100%;
  width: 100%;
}

.theme-card__details {
  padding: 10px;
}
.theme-card__title {
  height: 36px;
  overflow: hidden;
}
.theme-card__actions {
  position: absolute;
  z-index: 10;
  right: 0;
  bottom: 0;
  padding: 10px;
}

.tuby-upload-image__wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.tuby-upload-image__label {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.336);
  color: white;
  font-size: 18pt;
  cursor: pointer;
}

.tuby-upload-image__wrapper:hover .tuby-upload-image__label {
  opacity: 1;
}

.tuby-upload-image__input {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
}

.tuby-image__label {
  display: block;
  margin: 0 0 0.28571429rem 0;
  font-size: 0.92857143em;
  font-weight: 700;
  text-transform: none;
}
.tuby-image__label--error {
  color: #d15353;
}

.tuby-input__label {
  margin-bottom: 5px;
}
.tuby-input--error {
  color: #d15353;
  font-size: 12px;
  padding-left: 5px;
}

input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  font-size: 100%;
  line-height: 1.5;
  background-color: white;
  margin: 0;
  width: 365px;
}

.ui.medium.image,
.ui.medium.images .image,
.ui.medium.images img,
.ui.medium.images svg {
  width: 365px;
  height: 300px;
  font-size: 1rem;
}

.tuby-input__label {
  margin-bottom: 5px;
}
.tuby-input--error {
  color: #d15353;
  font-size: 12px;
  padding-left: 5px;
}

.tuby-popover {
  position: 'absolute';
  z-index: '2';
}

.tuby-cover {
  position: 'fixed';
  top: '0px';
  right: '0px';
  bottom: '0px';
  left: '0px';
}

.tuby-buttom {
  margin: 100px;
  padding: 100px;
}

.tuby-color-picker {
  margin-bottom: 5px;
}

.channel-form {
  padding: 10px 50px !important;
  position: relative;
}

.channel-form_field {
  margin-bottom: 25px !important;
}

.channel-form_button {
  margin-top: 15px !important;
  text-align: center !important;
}
.channel-form--error-message {
  width: 100% !important;
}

.theme-info {
  display: flex;
}
.theme-info__detils {
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
}
.theme-info__row {
  padding: 2px 0;
  font-size: 15px;
}
.theme-info_thumnail {
  min-width: 160px;
}
.theme-info_thumnail--image {
  margin-left: 10px;
  margin-right: 10px;
}
@media only screen and (max-width: 768px) {
  .theme-info {
    display: block;
  }
  .theme-info_thumnail {
    min-width: 100%;
    margin-bottom: 30px;
  }
  .theme-info_thumnail img {
    width: 100% !important;
  }
}

.ui.card > .image,
.ui.cards > .card > .image {
  position: relative;
  display: block;
  flex: 0 0 auto;
  padding: 0;
  background: rgba(0, 0, 0, 0.05);
  height: 200px;
  width: 300px;
}

.theme-container {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 20px;
  word-break: break-all;
}

.theme-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-left: 20px;
}

.theme-column {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: wrap;
  min-width: 25%;
}

.theme-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 10px;
}


.pages-wrapper {
  width: 100%;
  height: 150vh;
  position: relative;
}

.admin-form {
  margin: 10px 50px !important;
}

.admin-form_field {
  margin-bottom: 25px !important;
}

.admin-form_button {
  margin-top: 15px !important;
  text-align: center !important;
}
.admin-form_error-message {
  width: 100% !important;
}

.ui.tiny.modal {
  min-width: 10px;
  margin: 0;
}

.video-card {
  position: relative;
}
.video-card:hover {
  cursor: pointer;
}
.video-card__image {
  height: 150px;
  position: relative;
}
.video-card__img {
  height: 100%;
  width: 100%;
}
.video-card__details {
  padding: 10px;
}

.video-card__playbtn {
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
  color: #fff;
  background: rgba(0, 0, 0, 0.3);
}
.video-card__title {
  height: 36px;
  overflow: hidden;
}
.video-card__details--item {
  padding: 3px 0;
}
.video-card__actions {
  position: absolute;
  z-index: 10;
  right: 0;
  bottom: 0;
  padding: 10px;
}
.video-card__details--channel-name {
  height: 40px;
  overflow: hidden;
}

.tuby-upload-image__wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.tuby-upload-image__label {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.336);
  color: white;
  font-size: 18pt;
  cursor: pointer;
}

.tuby-upload-image__wrapper:hover .tuby-upload-image__label {
  opacity: 1;
}

.tuby-upload-image__input {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
}

.tuby-image__label {
  display: block;
  margin: 0 0 0.28571429rem 0;
  font-size: 0.92857143em;
  font-weight: 700;
  text-transform: none;
}
.tuby-image__label--error {
  color: #d15353;
}

.tuby-input__label {
  margin-bottom: 5px;
}
.tuby-input--error {
  color: #d15353;
  font-size: 12px;
  padding-left: 5px;
}

input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  font-size: 100%;
  line-height: 1.5;
  background-color: white;
  margin: 0;
  width: 365px;
}

.ui.medium.image,
.ui.medium.images .image,
.ui.medium.images img,
.ui.medium.images svg {
  width: 365px;
  height: 300px;
  font-size: 1rem;
}

.channel-form {
  padding: 10px 50px !important;
  position: relative;
}

.channel-form_field {
  margin-bottom: 25px !important;
}

.channel-form_button {
  margin-top: 15px !important;
  text-align: center !important;
}
.channel-form--error-message {
  width: 100% !important;
}

.pages-wrapper {
  width: 100%;
  height: 100vh;
  position: relative;
}

@font-face {
  font-family: 'Conv_STC-Regular';
  src: url('/fonts/STC-Regular.eot');
  src: local('?'), url('/fonts/STC-Regular.woff') format('woff'),
    url('/fonts/STC-Regular.ttf') format('truetype'),
    url('/fonts/STC-Regular.svg') format('svg');

  font-weight: normal;
  font-style: normal;
}
.swal-button--danger,
.swal-button--cancel,
.swal-button--confirm,
.swal-button--confirm:focus,
.swal-button--cancel:focus,
.swal-button--catch,
.swal-button--catch:focus {
  font-family: 'Roboto', sans-serif;
  border-radius: 3px;
  font-weight: 600;
  outline: none;
  box-shadow: 0 0 0;
}

.swal-button--danger:hover,
.swal-button--confirm:hover,
.swal-button--catch:hover {
  background-color: #d04067;
}
.swal-button:not([disabled]):hover {
  background-color: #d04067;
}
.swal-button--cancel:hover,
.swal-button--cancel:not([disabled]):hover {
  background-color: #e0dede;
}
.swal-button--danger,
.swal-button--catch,
.swal-button--confirm {
  background-color: #ea4773;
}
.swal-footer {
  text-align: center;
}

.ui.primary.button,
.ui.primary.buttons .button {
  background-color: #c73f76;
}
.ui.primary.button:hover,
.ui.primary.buttons .button:hover {
  background-color: #a93463;
}
.ui.primary.button:active,
.ui.primary.buttons .button:active {
  background-color: #642b74;
}
.ui.primary.button:focus,
.ui.primary.buttons .button:focus {
  background-color: #703081;
}

