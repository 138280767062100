.react-datepicker-component
  .react-datepicker-input
  .button-wrapper
  .input-button {
  margin: 0 10px;
  background: transparent;
  font-size: 15px;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
  color: #f90084;
}
